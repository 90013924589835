html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ps_data_r {
  text-align: right;
  padding: .25em 1em;
}

.ps_data_l {
  text-align: left;
  padding: .25em 1em;
}

.ps_data_c {
  text-align: center;
  padding: .25em .5em;
}

.ps_data_nw {
  padding: .25em 1em;
  white-space: nowrap;
}

.ps_header_c {
  text-align: center;
  font-weight: 600;
  padding: .25em .5em;
  background-color: gainsboro;
}

.w2_heading {
  font-size: 8pt;
  padding: .5em;
}

.w2_value {
  display: flex;
  justify-content: center;
  padding: .25em 0;
}

.bl {
  border-left: 1px solid black;
}

.br {
  border-right: 1px solid black;
}

.bb {
  border-bottom: 1px solid black;
}

.ein {
  grid-area: ein;
}
.wages {
  grid-area: wages;
}

.fed_tax {
  grid-area: fed_tax;
}

.med_wages {
  grid-area: med_wages;
}
.med_tax {
  grid-area: med_tax;
}
.ss_wages {
  grid-area: ss_wages;
}
.ss_tax {
  grid-area: ss_tax;
}
.employer_name {
  grid-area: employer_name;
}
.ctrl_num {
  grid-area: ctrl_num;
}
.emp_name {
  grid-area: emp_name;
}
.ss_tips {
  grid-area: ss_tips;
}
.allocated_tips {
  grid-area: allocated_tips;
}
.box9 {
  grid-area: box9;
}
.dependent_benefits {
  grid-area: dependent_benefits;
}
.non_qual_plans {
  grid-area: non_qual_plans;
}
.box12a {
  grid-area: box12a;
}
.box12b {
  grid-area: box12b;
}
.box12c {
  grid-area: box12c;
}
.box12d {
  grid-area: box12d;
}
.box13 {
  grid-area: box13;
}
.box14 {
  grid-area: box14;
}
.employee_info {
  grid-area: employee_info;
}

.w2_data_box {
  display: flex;
  flex-direction: column;
}

.w2_top {
  display: grid;
  grid-template-columns: 5fr 2fr 2fr;
  grid-template-areas: 
    "ein wages fed_tax"
    "employer_name ss_wages ss_tax"
    "employer_name med_wages med_tax"
    "employer_name ss_tips allocated_tips"
    "ctrl_num box9 dependent_benefits"
    "employee_info non_qual_plans box12a"
    "employee_info box13 box12b"
    "employee_info box14 box12c"
    "employee_info box14 box12d";
}

.w2_bottom {
  display: grid;
  grid-template-columns: 60px 160px 150px 120px 150px 120px 110px;
}
